:root {
  --primary-color: #f8971c;
  --secondary-color: #ed2324;
  --tertiary-color: #4cb052;
}

body {
  margin-top: 100px;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  list-style-type: none;
  text-decoration: none;
  color: inherit;
}

img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.header-logo {
  width: auto;
  height: 72px;
  color: var(--primary-color);
  cursor: pointer;
}

.selected-nav {
  color: var(--tertiary-color);
}

.drawer-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  animation-name: open-drawer-background;
  animation-duration: 225ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.drawer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: white;
  animation-name: open-drawer;
  animation-duration: 225ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  padding: 32px 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.drawer-logo {
  width: 156px;
  height: auto;
  color: var(--primary-color);
  cursor: pointer;
}

.drawer-x {
  color: var(--gray-color);
  position: absolute;
  right: 106px;
  top: 20px;
  font-size: 24px;
  cursor: pointer;
  padding: 16px;
}

.drawer-list {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}

.home-order-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.secondary-button:hover {
  background-color: var(--primary-color);
  color: white;
  border: 2px solid transparent;
}

.footer-section-header {
  font-size: 24px;
  margin-bottom: 16px;
}

.footer-text {
  font-size: 16px;
}

.footer-hours {
  width: 200px;
}

.footer-store-info-container {
  margin-bottom: 64px;
}

.footer-logo-container,
.footer-store-info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-logo {
  height: auto;
  width: 142px;
}

.hours-container {
  display: flex;
  justify-content: space-between;
}

.jumbotron-image {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.menu-header {
  text-align: center;
  padding: 64px;
}

.menu-navigation-container {
  margin: 0px 64px;
  border-bottom: 4px solid rgb(0, 0, 0, 0.1);
}

.active-menu-link {
  color: var(--tertiary-color);
  border-bottom: 4px solid var(--primary-color);
  box-shadow: 0 4px 2px -2px gray;
}

.tertiary-button {
  height: 44px;
  padding: 0 48px;
  background-color: var(--tertiary-color);
  border: 2px solid transparent;
  color: white;
  cursor: pointer;
}

.tertiary-button:hover {
  background-color: white;
  color: var(--tertiary-color);
  border: 2px solid var(--tertiary-color);
}

.menu-item-container {
  margin-bottom: 32px;
}

.menu-item-paragraph {
  margin-bottom: 8px;
}

.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
  text-align: center;
}

.contact-us-container > * {
  margin-bottom: 32px;
}

.google-map-container {
  width: 100%;
  height: 700px;
}

.menu-item-text-container {
  width: 100%;
}

@keyframes open-drawer-background {
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes open-drawer {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
    right: 0;
  }
}

@media (min-width: 769px) {
  .header-container {
    height: 100px;
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100000;
    background-color: white;
    box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, 0.063);
  }
  .header-list {
    display: flex;
  }

  .header-list-item {
    margin-right: 40px;
    color: var(--gray-color);
    cursor: pointer;
  }

  .header-list-item:last-child {
    margin: 0;
  }

  .hamburger-nav {
    display: none;
  }

  .home-jumbotron-container {
    height: 600px;
  }

  .section-header {
    font-weight: normal;
    font-size: 48px;
  }

  .section-sub-header {
    font-weight: normal;
    font-size: 32px;
  }

  .home-menu-container {
    padding: 0 128px;
    display: flex;
    justify-content: center;
  }

  .home-menu-text-container {
    margin: 32px 0;
    margin-right: 16px;
    padding-right: 16px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .home-menu-header {
    margin-bottom: 24px;
  }

  .home-menu-paragraph {
    margin-bottom: 24px;
  }

  .home-menu-image-container {
    width: 450px;
  }

  .home-order-header {
    margin-bottom: 32px;
  }

  .home-order-text-container {
    padding: 32px 64px;
    max-width: 550px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
  }

  .home-order-container {
    position: relative;
    padding: 64px 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
  }

  .home-contact-container {
    display: none;
  }

  .secondary-button {
    height: 44px;
    padding: 0 24px;
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .menu-jumbotron-container {
    height: 320px;
    position: relative;
  }

  .menu-list-container {
    padding: 64px 156px;
  }

  .menu-link {
    margin-right: 32px;
    padding: 0px 16px 8px 16px;
    display: inline-block;
    cursor: pointer;
  }

  .menu-link:last-child {
    margin-right: 0px;
  }

  .menu-item-image-container {
    width: 220px;
    height: 220px;
    margin-left: 40px;
    flex-shrink: 0;
  }

  .price-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .menu-item-body-container {
    display: flex;
    justify-content: space-between;
  }

  .menu-mobile-navigation {
    display: none;
  }

  .footer-container {
    padding: 64px 128px;
    background-color: black;
    color: white;
    font-weight: normal;
  }

  .menu-item-header {
    color: var(--tertiary-color);
    margin-bottom: 16px;
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .header-container {
    height: 100px;
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, 0.063);
  }

  .header-list {
    display: none;
  }

  .header-list-item {
    margin-bottom: 40px;
    cursor: pointer;
  }

  .header-list-item:last-child {
    margin: 0;
  }

  .hamburger-nav {
    padding: 16px;
    margin-right: -16px;
    font-size: 24px;
    cursor: pointer;
  }

  .home-jumbotron-container {
    height: 450px;
  }

  .section-header {
    font-size: 32px;
  }

  .section-sub-header {
    font-weight: normal;
    font-size: 32px;
    margin-bottom: 16px;
  }

  .home-menu-container {
    display: flex;
    flex-direction: column;
  }

  .home-menu-text-container {
    margin: 32px auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .home-menu-paragraph {
    margin-bottom: 16px;
  }

  .home-menu-image-container {
    height: 300px;
  }

  .home-order-header {
    /* width: 200px; */
    text-align: center;
    margin-bottom: 24px;
  }

  .home-order-text-container {
    display: none;
  }

  .home-order-container {
    position: relative;
    padding: 64px 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }

  .home-contact-container {
    padding: 32px 64px;
    max-width: 550px;
    height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: white;
  }

  .secondary-button {
    height: 44px;
    width: 200px;
    padding: 0 24px;
    background-color: white;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    cursor: pointer;
  }

  .menu-jumbotron-container {
    height: 160px;
    position: relative;
  }

  .menu-list-container {
    padding: 64px 64px;
  }

  .menu-link {
    margin-right: 16px;
    padding: 0px 16px 8px 16px;
    display: inline-block;
    cursor: pointer;
  }

  .menu-link:last-child {
    margin-right: 0px;
  }

  .menu-item-image-container {
    width: 160px;
    height: 160px;
    flex-shrink: 0;
    margin-top: 24px;
  }

  .menu-desktop-container {
    display: none;
  }

  .price-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    margin-right: 40px;
  }

  .menu-item-body-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 64px;
  }

  .menu-mobile-navigation {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
  }

  .menu-mobile-dropdown-container {
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.2);
    padding: 32px 16px;
  }

  .menu-mobile-list-item {
    padding: 20px 0;
  }

  .menu-mobile-list-item:last-child {
    margin-bottom: 0;
  }

  .mobile-dots {
    font-size: 24px;
    margin-right: 16px;
  }

  .footer-container {
    padding: 64px 116px 32px 116px;
    background-color: black;
    color: white;
    font-weight: normal;
    text-align: center;
  }

  .footer-info-container {
    margin-bottom: 32px;
  }

  .footer-logo-container,
  .footer-store-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-item-header {
    color: var(--tertiary-color);
    margin-bottom: 16px;
    font-size: 24px;
  }
}
